.header-block{
    svg {
        height: 90px;
        transform: scale(1.5);
        pointer-events: none;
    }
    .icon-block{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .btn-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        width:35px;
        height:35px;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
        box-shadow: 1px 3px lightgray;
        a{
            color: red;
        }
    }
}