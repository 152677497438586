@import "~rfs/scss";

@mixin textLato($weight, $size, $line, $color, $opacity) {
    font-family: "Lato", sans-serif;
    font-weight: $weight;
    font-size: $size;
    line-height: $line;
    color: $color;
    opacity: $opacity;
}

@mixin textMonserrat($weight, $size, $line, $color, $opacity) {
    font-family: "Montserrat", sans-serif;
    font-weight: $weight;
    font-size: $size;
    line-height: $line;
    color: $color;
    opacity: $opacity;
}

$purpleColor: #4f205d;
$magentaColor: #642a73;
$lightGreyColor: #d2c8c8;
$greyColor: #efefef;
$midGreyColor: #756969;
$whiteColor: #fff;
$pinkColor: #be0348;

.whiteColor{
    color: $whiteColor;
}

.magentaColor{
    color: $magentaColor;
}

.purpleColor{
    color: $purpleColor;
}

.midGreyColor{
    color: $midGreyColor;
}

.size20{
    @include font-size(20px);
}

.size25{
    @include font-size(25px);
}

.size30{
    @include font-size(30px);
}

.size35{
    @include font-size(35px);
}

.size40{
    @include font-size(40px);
}


@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}